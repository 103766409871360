<template>
  <v-sheet class="px-12 pt-0">
    <v-sheet
      class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page"
      style="height: calc(100vh - 188px)"
    >
      <v-btn
        to="/kelembagaan/struktur_organisasi"
        absolute
        fab
        dark
        color="primary"
        style="left: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon
        >
      </v-btn>
      <v-btn
        to="/kelembagaan/otonomi_daerah"
        class=""
        absolute
        fab
        dark
        color="primary"
        style="right: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon
        >
      </v-btn>
      <v-row class="pa-12">
        <v-card
          class="rounded-xl overflow-y-auto"
          elevation="12"
          style="height: calc(100vh - 280px)"
        >
          <v-card flat class="d-flex justify-center mb-6">
            <v-btn rounded style="margin-top: 8px" color="white"
              >Pelaksanaan Fasilitasi Pemerintahan</v-btn
            >
          </v-card>

          <v-card flat class="d-flex justify-center" color="" width="100%">
            <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

            <v-card
              flat
              width="100%"
              height="100%"
              class="d-flex align-center flex-column pa-16"
              transition="scale-transition"
            >
              <div class="home-content content-desc">
                <p class="align-center">
                  <center>
                    PERATURAN GUBERNUR JAWA BARAT NOMOR 133 TAHUN 2022 PASAL 6
                  </center>
                </p>

                <ul>
                  <li>
                    Menyelenggarakan fasilitasi penyelenggaraan tugas
                    dekonsentrasi dan tugas pembantuan meliputi penyusunan
                    petunjuk pelaksanaan pemerintah dan penyusunan analisis data
                    tugas dekonsentrasi dan tugas pembantuan, serta
                    pengoordinasian dan fasilitasi kesekretariatan Gubernur
                    sebagai wakil Pemerintah Pusat.
                  </li>
                  <li>
                    Menyelenggarakan fasilitasi pembentukan Kawasan khusus
                    bencana, kebakaran serta gangguan ketenteraman dan
                    ketertiban;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan rapat koordinasi Forum
                    Komunikasi Pimpinan Daerah (Forkopimda) Provinsi;
                  </li>
                  <li>
                    Menyelenggarakan fasilitasi penyusunan bahan pembentukan Tim
                    Persiapan Pengadaan Tanah serta penyusunan bahan Keputusan
                    Gubernur tentang penetapan lokasi pengadaan tanah bagi
                    pembangunnan untuk kepentingan umum;
                  </li>
                  <li>
                    Menyelenggarakan fasilitasi bahan perumusan kebijakan dalam
                    forum pelaksanaan penyusunan dan penyiapan bahan rumusan
                    kebijakan teknis administrasi pertanahan
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan pembentukan, penyesuaian,
                    pengabunggan dan pemekaran daerah, penataan daerah di Daerah
                    Provinsi dan Daerah Kabupaten/Kota serta penyusunan bahan
                    petunjuk pelaksanaan mengenai kode dan data wilayah
                    administrasi pemerintahan;
                  </li>
                  <li>
                    Menyelenggarakan fasilitasi adminitrasi penataan desa;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan pemberian bimbingan,
                    perumusan kebijakan dan koordinasi penyelenggaraan
                    administrasi wilayah pemerintahan, supervisi, pembinaan dan
                    sosialisasi pemberian namanama geografis, toponimi/rupa
                    bumi;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan fasilitasi penataan batas
                    wilayah administrasi pemerintahan antar Daerah Provinsi dan
                    Daerah Kabupaten Kota;
                  </li>
                </ul>

                <div class="kerja-sama-footer">
                  <div class="content-title" style="font-size: 32px">
                    <center>PEMERINTAHAN</center>
                  </div>
                  <center>
                    <v-img
                      class="shrink"
                      :src="`${publicPath}pemerintahan_ilustrasi.png`"
                      transition="scale-transition"
                      width="100%"
                      style="max-width: 500px"
                    />
                  </center>
                  <div class="item">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="
                          margin-top: 8px;
                          color: #1fac61 !important;
                          background-color: #ecebef !important;
                          padding: 50px !important;
                          font-size: 25px;
                        "
                        color="white"
                        >GGWP</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="
                          margin-top: 8px;
                          color: #1fac61 !important;
                          background-color: white !important;
                          padding: 50px !important;
                          font-size: 20px;
                        "
                        color="white"
                        >PENETAPAN LOKASI /<br />
                        PERTANAHAN</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="
                          margin-top: 8px;
                          color: #1fac61 !important;
                          background-color: #ecebef !important;
                          padding: 50px !important;
                          font-size: 25px;
                        "
                        color="white"
                        >BATAS DAERAH</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="
                          margin-top: 8px;
                          color: #1fac61 !important;
                          background-color: white !important;
                          padding: 50px !important;
                          font-size: 25px;
                        "
                        color="white"
                        >PENATAAN DESA</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="
                          margin-top: 8px;
                          color: #1fac61 !important;
                          background-color: #ecebef !important;
                          padding: 50px !important;
                          font-size: 25px;
                        "
                        color="white"
                        >DOB</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="
                          margin-top: 8px;
                          color: #1fac61 !important;
                          background-color: white !important;
                          padding: 50px !important;
                          font-size: 25px;
                        "
                        color="white"
                        >RUPA BUMI</v-btn
                      >
                    </v-card>
                  </div>
                </div>
              </div>
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "pemerintahan",

  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
};
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;
}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4caf50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
