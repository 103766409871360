<template>
  <Pemerintahan />
</template>

<script>
  import Pemerintahan from '@/components/kelembagaan/pemerintahan'

  export default {
    name: 'Pemerintahan-view',

    components: {
      Pemerintahan,
    },
  }
</script>
