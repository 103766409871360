<template>
  <v-sheet class="px-12 pt-0">
    <v-sheet class=" pb-6 px-8 pt-0">
      <div class="d-flex justify-center">
        <v-card width="33%" flat class="d-flex justify-end">
          <v-card flat tile color="primary" width="30%" height="100%">
            <v-card class="rounded-l-0 rounded-b-0 rounded-xl" flat tile color="white" width="100%"
              height="100%"></v-card>
          </v-card>
        </v-card>
        <v-card class="rounded-t-0 rounded-xl border-bottom-secondary" width="40%" color="primary" height="40px" flat>
          <v-bottom-navigation :value="value" background-color="primary"  horizontal dark>
            <v-btn to="/kelembagaan/tentang_kami">
              <span >Tentang Kami</span>

              <!-- <v-icon>mdi-history</v-icon> -->
            </v-btn>

            <v-btn to="/kelembagaan/struktur_organisasi">
              <span>Struktur Organisasi</span>

              <!-- <v-icon>mdi-heart</v-icon> -->
            </v-btn>

            <v-btn to="/kelembagaan/pemerintahan">
              <span>Pemerintahan</span>

              <!-- <v-icon>mdi-map-marker</v-icon> -->
            </v-btn>
            <v-btn to="/kelembagaan/otonomi_daerah">
              <span>Otonomi Daerah</span>

              <!-- <v-icon>mdi-map-marker</v-icon> -->
            </v-btn>
          </v-bottom-navigation>

        </v-card>
        <v-card width="30%" flat>
          <v-card flat tile color="primary" width="20%" height="100%">
            <v-card class="rounded-r-0 rounded-b-0 rounded-xl" flat tile color="white" width="100%"
              height="100%"></v-card>
          </v-card>
        </v-card>
      </div>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'beranda',

  data: () => ({
    value: 1,

  }),
}
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00A85D;
  border-left: 3px solid #00A85D;
  border-right: 3px solid #00A85D;
}

.border-bottom-secondary {
  border-bottom: 3px solid #F6C123;
}
</style>
