<template>
  <Berita />
</template>

<script>
  import Berita from '@/components/berita/berita'

  export default {
    name: 'Berita-view',

    components: {
      Berita,
    },
  }
</script>
