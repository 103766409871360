<template>
  <Rupabumi />
</template>

<script>
  import Rupabumi from '@/components/layanan/rupabumi'

  export default {
    name: 'Rupabumi-view',

    components: {
      Rupabumi,
    },
  }
</script>
