<template>
  <v-sheet class="px-12 pt-0">
    <v-sheet
      class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page"
      style="height: calc(100vh - 188px)"
    >
      <v-btn
        class=""
        to="/"
        absolute
        fab
        dark
        color="primary"
        style="left: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon
        >
      </v-btn>
      <v-btn
        to="/layanan/gwpp"
        class=""
        absolute
        fab
        dark
        color="primary"
        style="right: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon
        >
      </v-btn>
      <v-row class="pa-12">
        <v-card
          class="rounded-xl overflow-y-auto"
          elevation="12"
          style="height: calc(100vh - 280px)"
        >
          <v-card flat class="d-flex justify-center mb-6">
            <v-btn rounded style="margin-top: 8px" color="white"
              >Rupa Bumi</v-btn
            >
          </v-card>

          <v-card flat class="d-flex justify-center" color="" width="100%">
            <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

            <v-card
              flat
              width="100%%"
              height="100%"
              class="d-flex align-center flex-column pa-16"
              style="padding-top: 0px !important"
              transition="scale-transition"
            >
              <div
                id="definisi"
                class="section-content d-flex align-center flex-column"
              >
                <v-img
                  class="shrink content-logo-3"
                  :src="`${publicPath}bernada_icon_rupa_bumi.png`"
                  transition="scale-transition"
                  width="100%"
                />
                <div class="content-title">DEFINISI</div>
                <div class="home-content">
                  <p class="content-desc">
                    Menurut Peraturan Pemerintah nomor 2 Tahun 2021 tentang
                    Penyelenggaraan Nama Rupabumi. Rupabumi adalah permukaan
                    bumi beserta objek yang dapat dikenali identitasnya baik
                    berupa Unsur Alami maupun Unsur Buatan. Nama Rupabumi adalah
                    nama yang diberikan pada Unsur Rupabumi penyelenggaraan nama
                    rupabumi bertujuan untuk melindungi kedaulatan dan keamanan
                    wilayah Negara Kesatuan Republik Indonesia, melestarikan
                    nilai-nilai budaya, sejarah, dan adat istiadat serta
                    mewujudkan tertib administrasi pemerintahan
                  </p>
                </div>
              </div>

              <div
                id="dasar-hukum"
                class="section-content d-flex align-center flex-column"
                style="min-width: 100% !important"
              >
                <v-img
                  class="shrink content-logo"
                  :src="`${publicPath}penlok/law.png`"
                  transition="scale-transition"
                  width="100%"
                />
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >Dasar Hukum</v-btn
                    >
                  </v-card>
                </div>
                <div
                  class="home-content content-desc"
                  style="min-width: 100% !important"
                >
                  <ul style="min-width: 100% !important">
                    <li>
                      Peraturan Pemerintah Nomor 2 Tahun 2021 tentang
                      Penyelenggaraan nama Rupabumi
                    </li>
                    <li>
                      SURAT EDARAN KEMENDAGRI No. 125/2556/BAK Tanggal 5 Mei
                      2021
                    </li>
                    <li>
                      SURAT EDARAN GUBERNUR No. 5865/PEM.0201.02/PemOtda tanggal
                      11 Oktober 2021
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="ruang-lingkup"
                class="section-content d-flex align-center flex-column"
                style="min-width: 100% !important"
              >
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >MENGAPA PERLU NAMA RUPABUMI?</v-btn
                    >
                  </v-card>
                </div>
                <div
                  class="home-content content-desc"
                  style="min-width: 100% !important"
                >
                  <ul style="min-width: 100% !important">
                    <li>Mandat dari PBB untuk membakukan nama rupabumi</li>
                    <li>
                      Memudahkan berkoordinasi, berkomunikasi, dan menyampaikan
                      informasi
                    </li>
                    <li>
                      Memperoleh keseragaman penamaan unsur rupabumi yang sesuai
                      dengan ketentuan
                    </li>
                    <li>Mandat dari PBB untuk membakukan nama rupabumi</li>
                    <li>Mewujudkan tertib administrasi pemerintahan</li>
                    <li>
                      Mewujudkan tersedianya informasi geospasial toponim dalam
                      gazeter nasional
                    </li>
                    <li>
                      Mendukung perencanaan pembangunan nasional yang akurat dan
                      dapat dipertanggungjawabkan
                    </li>
                    <li>
                      Identitas lokal dan nasional yang dapat mencerminkan
                      bahasa dan jati diri bangsa,
                    </li>
                    <li>
                      Petunjuk untuk pelacakan perjalanan sejarah bangsa dan
                      budaya di masa lalu
                    </li>
                  </ul>
                </div>
              </div>
              <div
                id="dasar-hukum"
                class="section-content d-flex align-center flex-column"
              >
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >PENELAAHAN UNSUR RUPABUMI</v-btn
                    >
                  </v-card>
                </div>
                <v-img
                  class="shrink content-logo-2"
                  :src="`${publicPath}penelaah.png`"
                  transition="scale-transition"
                  width="100%"
                />
              </div>

              <div
                id="dasar-hukum"
                class="section-content d-flex align-center flex-column"
              >
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >Alur Penyelenggaraan Nama Rupabumi (PNR)</v-btn
                    >
                  </v-card>
                </div>
                <v-img
                  class="shrink content-logo-2"
                  :src="`${publicPath}pnr.png`"
                  transition="scale-transition"
                  width="100%"
                />
              </div>
              <div
                id="ruang-lingkup"
                class="section-content d-flex align-center flex-column"
                style="min-width: 100% !important"
              >
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >PENERIMA MANFAAT NAMA RUPABUMI?</v-btn
                    >
                  </v-card>
                </div>
                <div
                  class="home-content content-desc"
                  style="min-width: 100% !important"
                >
                  <ul style="min-width: 100% !important">
                    <li>
                      Kartografer (pembuat peta) dan lembaga survei pemetaan
                    </li>
                    <li>Badan statistik: data sensus penduduk</li>
                    <li>Urusan Pemerintahan: tertib administrasi</li>
                    <li>Bidang hukum, ketertiban, pertahanan, dan keamanan</li>
                    <li>
                      Perusahaan/ badan usaha dalam mengembangkan nama merek
                      (branding)
                    </li>
                    <li>Penanggulangan bencana dan aksi kemanusiaan</li>
                    <li>
                      Layanan darurat: kebakaran, pencarian dan pertolongan
                    </li>
                    <li>
                      Layanan darurat: kebakaran, pencarian dan
                      pertolonganLayanan darurat: kebakaran, pencarian dan
                      pertolongan
                    </li>
                    <li>
                      Sistem navigasi darat, laut, udara, penunjuk arah jalan
                    </li>
                  </ul>
                </div>
              </div>
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "layanan-rupabumi",

  data: () => ({
    publicPath: process.env.BASE_URL,
    password: true,
  }),
  methods: {
    show_password() {
      this.password = false;
    },
    hide_password() {
      this.password = true;
    },
  },
};
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;
}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4caf50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
