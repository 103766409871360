<template>
  <v-app>
    <Appbar/>
    <v-main>
      <router-view/>
    </v-main>
    <Footer_bottom />
  </v-app>
</template>

<script>
import Appbar from '@/components/app_bar';
import Footer_bottom from '@/components/footer';

export default {
  name: 'App',
  components: {
    Appbar,
    Footer_bottom,
  },

  data: () => ({
    //
  }),
};
</script>
