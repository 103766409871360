<template>
  <v-sheet class="px-12 pt-0 ">
    <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
      <v-btn to="/kelembagaan/tentang_kami" absolute fab dark color="primary" style="left: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon>
      </v-btn>
      <v-btn to="/kelembagaan/pemerintahan" class="" absolute fab dark color="primary"
        style="right: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon>
      </v-btn>
      <v-row class="pa-12">
        
          <v-card width="100%" class=" rounded-xl  overflow-y-auto " elevation="12" style="height: calc(100vh - 280px)">
            <v-card flat class="d-flex justify-center mb-6">
              <v-btn rounded style="margin-top: 8px" color="white">Struktur Organisasi</v-btn>
            </v-card>
           
            <v-card flat class="d-flex justify-center" color="" width="100%">
              <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

             
              <!-- Your content for the second column goes here -->
              <img :src="`${publicPath}organisasi.png`" class="home-img">
            
            </v-card>
          </v-card>
          
     
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'struktur_organisasi',

  data: () => ({
    publicPath: process.env.BASE_URL,

  }),
}
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00A85D;
  border-left: 3px solid #00A85D;
  border-right: 3px solid #00A85D;
}

.v-tab--active {
  background-color: #00A85D;
  color: white;
}


</style>
