<template>
  <Lppd />
</template>

<script>
  import Lppd from '@/components/layanan/lppd'

  export default {
    name: 'Lppd-view',

    components: {
      Lppd,
    },
  }
</script>
