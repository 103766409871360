<template>
  <v-sheet class="px-12 pt-0">
    <v-sheet
      class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page"
      style="height: calc(100vh - 188px)"
    >
      <v-btn
        class=""
        disabled
        absolute
        fab
        dark
        color="primary"
        style="left: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon
        >
      </v-btn>
      <v-btn
        to="/beranda/layanan"
        class=""
        absolute
        fab
        dark
        color="primary"
        style="right: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon
        >
      </v-btn>
      <v-row class="">
        <v-card
          color=""
          width="100%"
          height="100%"
          flat
          class="d-flex justify-center align-center mt-8"
        >
          <v-card
            flat
            width="400"
            height="100%"
            class="d-flex align-center flex-column"
            transition="scale-transition"
          >
            <v-card flat width="100%">
              <h1 style="text-align: justify">Kontak</h1>
            </v-card>
            <v-card flat width="100%" class="mt-2">
              <p style="text-align: justify">
                Temukan kami di Jl. Diponegoro No. 22 <br />
                Kota Bandung – 40115
              </p>
            </v-card>
            <v-card flat width="100%" class="mt-2 d-flex align-center">
              <v-btn text color="#00ADEB"> Kontak </v-btn>
              <router-link to="/">
                <v-btn rounded large color="#00ADEB" dark class="mr-6">
                  Aplikasi
                </v-btn>
              </router-link>
            </v-card>
            <!-- <h1>BERANDA</h1> -->
          </v-card>
          <v-card
            flat
            width="50%"
            height="350"
            class="d-flex justify-center align-center flex-wrap"
            color="greenx lighten-4"
          >
            <v-card
              flat
              width="200"
              height="185"
              class="d-flex align-center flex-column"
            >
              <v-img
                class="shrink"
                contain
                :src="`${publicPath}mail.png`"
                transition="scale-transition"
              />
            </v-card>
            <v-card
              flat
              width="200"
              height="185"
              class="d-flex align-center flex-column"
            >
              <v-img
                class="shrink"
                contain
                :src="`${publicPath}insta.png`"
                transition="scale-transition"
              />
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "beranda",

  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
};
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}
</style>
