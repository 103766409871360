<template>
  <Layanan />
</template>

<script>
  import Layanan from '@/components/beranda/layanan'

  export default {
    name: 'Layanan-view',

    components: {
      Layanan,
    },
  }
</script>
