<template>
  <Informasi_terkini />
</template>

<script>
import Informasi_terkini from '@/components/beranda/informasi_terkini'

  export default {
    name: 'Informasi_terkini-view',

    components: {
      Informasi_terkini,
    },
  }
</script>
