<template>
  <Paw_dprd />
</template>

<script>
  import Paw_dprd from '@/components/layanan/paw_dprd'

  export default {
    name: 'Paw_dprd-view',

    components: {
      Paw_dprd,
    },
  }
</script>
