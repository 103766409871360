<template>
  <v-sheet class="px-12 pt-0 ">
    <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
      <v-btn to="/kelembagaan/otonomi_daerah" absolute fab dark color="primary" style="left: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon>
      </v-btn>
      <v-btn to="/kelembagaan/kerjasama" class="" absolute fab dark color="primary"
        style="right: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon>
      </v-btn>
      <v-row class="pa-12">
        
          <v-card  class=" rounded-xl  overflow-y-auto " elevation="12" style="height: calc(100vh - 280px)">
            <v-card flat class="d-flex justify-center mb-6">
              <v-btn rounded style="margin-top: 8px" color="white">Pengadaan Tanah melalui Penetapan Lokasi</v-btn>
            </v-card>
           
            <v-card flat class="d-flex justify-center" color="" width="100%">
              <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

              <v-card flat width="100%%" height="100%" class="d-flex align-center flex-column pa-16"
                transition="scale-transition">

                <div id="definisi" class="section-content d-flex align-center flex-column">
                  <v-img class="shrink content-logo" :src="`${publicPath}penlok/map.png`" transition="scale-transition" width="100%" />
                  <div class="content-title">
                    DEFINISI
                  </div>
                  <div class="home-content">  
                    <p class="content-desc">
                      Menurut Undang-Undang Republik Indonesia Nomor 2 Tahun 2012 Tentang Pengadaan Tanah Bagi
                      Pembangunan Untuk Kepentingan Umum, Pengadaan Tanah adalah kegiatan menyediakan tanah
                      dengan cara memberi ganti kerugian yang layak dan adil kepada pihak yang berhak, sedangkan
                      Penetapan Lokasi dalam Peraturan Menteri Agraria dan Tata Ruang/ Kepala Badan Pertanahan
                      Nasional Republik Indonesia Nomor 19 Tahun 2021 Tentang Ketentuan Pelaksanaan Peraturan
                      Pemerintah Nomor 19 Tahun 2021 Tentang Penyelenggaraan Pengadaan Tanah Bagi Pembangunan
                      Untuk Kepentingan Umum, yaitu Penetapan Lokasi adalah penetapan atas lokasi pembangunan
                      untuk Kepentingan Umum yang ditetapkan dengan keputusan gubernur/bupati/wali kota yang
                      dipergunakan sebagai izin untuk Pengadaan Tanah, perubahan penggunaan tanah, dan peralihan
                      Hak Atas Tanah dalam Pengadaan Tanah bagi pembangunan untuk Kepentingan Umum.
                    </p>
                    
                   
                  </div>
                </div>
                
                <div id="dasar-hukum" class="section-content d-flex align-center flex-column">
                  <v-img class="shrink content-logo" :src="`${publicPath}penlok/law.png`" transition="scale-transition" width="100%" />
                  <div class="content-title">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn rounded style="margin-top: 8px" color="white">Dasar Hukum</v-btn>
                    </v-card>
                  </div>
                  <div class="home-content content-desc">  
                    <ul>

                      <li>
                        Undang-Undang Nomor 2 Tahun 2012 tentang Pengadaan Tanah Bagi Pembangunan
                        Untuk Kepentingan Umum (Lembaran Negara Republik Indonesia Tahun 2012 Nomor
                        22, Tambahan Lembaran Negara Republik Indonesia Nomor 5280) sebagaimana telah
                        diubah beberapa kali, terakhir dengan Undang-Undang Nomor 6 Tahun 2023 tentang
                        Penetapan Peraturan Pemerintah Pengganti Undang-Undang Nomor 2 Tahun 2022
                        tentang Cipta Kerja Menjadi Undang-Undang (Lembaran Negara Republik Indonesia
                        Tahun 2023 Nomor 41, Tambahan Lembaran Negara Republik Indonesia Nomor
                        6856);
                      </li>
                      <li>
                        Peraturan Pemerintah Nomor 19 Tahun 2021 tentang Penyelenggaraan Pengadaan
                        Tanah Bagi Pembangunan Untuk Kepentingan Umum (Lembaran Negara Republik
                        Indonesia Tahun 2021 Nomor 29, Tambahan Lembaran Negara Republik Indonesia
                        Nomor 6631), sebagaimana telah diubah dengan Peraturan Pemerintah Nomor 39
                        Tahun 2023 tentang Perubahan atas Peraturan Pemerintah Nomor 19 Tahun 2021
                        tentang Penyelenggaraan Pengadaan Tanah Bagi Pembangunan Untuk Kepentingan
                        Umum (Lembaran Negara Republik Indonesia Tahun 2023 Nomor 102, Tambahan
                        Lembaran Negara Republik Indonesia Nomor 6885);
                      </li>
                      <li>
                        Peraturan Presiden Nomor 3 Tahun 2016 tentang Percepatan Pelaksanaan Proyek
                        Strategis Nasional (Lembaran Negara Republik Indonesia Tahun 2016 Nomor 4)
                        sebagaimana telah diubah beberapa kali, terakhir dengan Peraturan Presiden Nomor
                        109 Tahun 2020 tentang Perubahan Ketiga atas Peraturan Presiden Nomor 3 Tahun
                        2016 tentang Percepatan Pelaksanaan Proyek Strategis Nasional (Lembaran Negara
                        Republik Indonesia Tahun 2020 Nomor 259);
                      </li>
                      <li>
                        Peraturan Menteri Koordinator Bidang Perekonomian Nomor 7 Tahun 2021 tentang
                        Perubahan Daftar Proyek Strategis Nasional (Lembaran Negara Republik Indonesia
                        Tahun 2021 Nomor 1034) sebagaimana telah diubah beberpa kali, terakhir dengan
                        Peraturan Menteri Koordinator Bidang Perekonomian Nomor 7 Tahun 2023 tentang
                        Perubahan Ketiga atas Peraturan Menteri Koordinator Bidang Perekonomian Nomor
                        7 Tahun 2021 tentang Perubahan Daftar Proyek Strategis Nasional (Berita Negara
                        Republik Indonesia Tahun 2023 Nomor 675);
                      </li>
                      <li>
                        Peraturan Menteri Agraria dan Tata Ruang/Kepala Badan Pertanahan Nasional
                        Nomor 19 Tahun 2021 tentang Ketentuan Pelaksanaan Peraturan Pemerintah Nomor
                        19 Tahun 2021 tentang Penyelenggaraan Pengadaan Tanah bagi Pembangunan untuk
                        Kepentingan Umum (Berita Negara Republik Indonesia Tahun 2021 Nomor 672);
                      </li>
                    </ul>
                  </div>
                </div>
                
                <div id="ruang-lingkup" class="section-content d-flex align-center flex-column">
                  
                  <div class="content-title">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn rounded style="margin-top: 8px" color="white">Ruang Lingkup</v-btn>
                    </v-card>
                  </div>
                  <div class="home-content">  
                    <div class="d-flex">
                        <div width="50%">
                          <v-img class="shrink content-img" :src="`${publicPath}ilustrasi_1_beranda.png`" transition="scale-transition" width="320px" />

                        </div>
                        <div width="50%">
                          <p class="content-desc">
                            Pengadaan tanah merupakan perbuatan hukum berupa melepaskan hubungan hukum yang semula ada
                            antara pemegang hak dan tanahnya yang diperlukan, dengan pemberian imbalan dalam bentuk uang,
                            fasilitas atau lainnya, melalui musyawarah untuk mencapai kata sepakat antara empunya tanah dan pihak
                            yang memerlukan. Proses pelepasan hak atas kepemilikan orang atas tanah dan/atau benda-benda yang
                            ada diatasnya yang dilakukan secara sukarela.
                            Pengaturan pengadaan tanah bagi pembangunan untuk kepentingan umum diatur dalam Undang-Undang
                            Nomor 2 Tahun 2012 tentang Pengadaan Tanah Bagi Pembangunan Untuk Kepentingan Umum dan
                            Peraturan Pelaksanaannya diatur dengan Peraturan Presiden dan Peraturan Kepala Badan Pertanahan
                            Nasional Republik Indonesia. Pengadaan tanah bagi pembangunan untuk kepentingan umum dilaksanakan
                            melalui beberapa tahapan, yaitu tahap perencanaan, persiapan, pelaksanaan dan penyerahan hasil
                            Penyelenggaraan pengadaan tanah juga sering bersinggungan dengan isu hukum mendasar seperti hak
                            asasi manusia, prinsip keadilan, prinsip keseimbangan antara kepentingan negara dengan kepentingan
                            masyarakat baik secara individu maupun kelompok.
                            Dalam tahapan persiapan, Gubernur membentuk Tim Persiapan dalam waktu paling lama 10 hari kerja,
                            yang beranggotakan Bupati/Walikota, Perangkat daerah provinsi terkait, instansi yang memerlukan
                            tanah, dan instansi terkait lainnya. Untuk kelancaran pelaksanaan tugas Tim Persiapan, Gubernur
                            membentuk sekretariat persiapan Pengadaan Tanah yang berkedudukan di Sekretariat Daerah Provinsi

                            Tugas tim persiapan sebagai berikut :
                          </p>      
                        </div>


                    </div>
                    
                    <div class="home-content content-desc">  
                    <ul>

                      <li>
                        Melaksanakan Pemberitahuan Rencana Pembangunan
                        Pemberitahuan rencana pembangunan ditandatangani Ketua Tim Persiapan dan diberitahukan kepada masyarakat pada lokasi rencana
                        pembangunan, Pemberitahuan dapat dilakukan secara langsung melalui sosialisasi, tatap muka, dan/atau surat pemberitahuan, atau
                        melalui pemberitahuan secara tidak langsung melalui media cetak maupun media elektronik.
                      </li>
                      <li>
                        Melakukan Pendataan Awal Lokasi Rencana Pengadaan
                        Pendataan awal lokasi rencana pengadaan meliputi kegiatan pengumpulan data awal Pihak yang Berhak dan Objek Pengadaan Tanah
                        bersama aparat kelurahan/desa. Hasil pendataan dituangkan dalam bentuk daftar sementara lokasi rencana pembangunan yang
                        ditandatangani Ketua Tim Persiapan sebagai bahan untuk pelaksanaan Konsultasi Publik rencana pembangunan.
                      </li>
                      <li>
                        Melaksanakan Konsultasi Publik Rencana Pembangunan
                        Konsultasi Publik rencana pembangunan dilakukan untuk mendapatkan kesepakatan lokasi rencana pembangunan dari Pihak yang Berhak
                        dan masyarakat yang terkena dampak, Hasil kesepakatan atas lokasi rencana pembangunan dituangkan dalam berita acara kesepakatan.
                      </li>
                      <li>
                        Menyiapkan Penetapan Lokasi Pembangunan
                        Penetapan Lokasi Pembangunan dibuat berdasarkan kesepakatan yang telah dilakukan Tim Persiapan dengan Pihak yang Berhak atau
                        berdasarkan karena ditolaknya keberatan dari Pihak yang Keberatan. Penetapan Lokasi Pembangunan dilampiri peta lokasi pembangunan
                        yang disiapkan oleh instansi yang memerlukan tanah. Penetapan Lokasi Pembangunan berlaku untuk jangka waktu 3 tahun dan dapat
                        dilakukan permohonan perpanjangan waktu 1 kali untuk waktu paling lama.
                      </li>
                      <li>
                        Mengumumkan Penetapan Lokasi Pembangunan
                        Pengumuman atas Penetapan Lokasi Pembangunan untuk kepentingan umum di lokasi pembangunan maupun diumumkan melalui media
                        cetak dan/atau media elektronik. Pengumuman Penetapan Lokasi Pembangunan dilaksanakan selama paling kurang 14 hari kerja.
                      </li>
                    </ul>
                  </div>
                   
                  </div>
                </div>

              </v-card>

              
              
            </v-card>
          </v-card>
          
     
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'tentang_kami',

  data: () => ({
    publicPath: process.env.BASE_URL,

  }),
}
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00A85D;
  border-left: 3px solid #00A85D;
  border-right: 3px solid #00A85D;
}

.v-tab--active {
  background-color: #00A85D;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;

}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4CAF50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
