<template>
  <v-sheet class="px-12 pt-0">
    <v-sheet
      class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page"
      style="height: calc(100vh - 188px)"
    >
      <v-btn
        to="/kelembagaan/penlok"
        absolute
        fab
        dark
        color="primary"
        style="left: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon
        >
      </v-btn>
      <v-btn
        to="/kelembagaan/kerjasama-dalam-negri"
        class=""
        absolute
        fab
        dark
        color="primary"
        style="right: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon
        >
      </v-btn>
      <v-row class="pa-12">
        <v-card
          class="rounded-xl overflow-y-auto"
          elevation="12"
          style="height: calc(100vh - 280px)"
        >
          <v-card flat class="d-flex justify-center mb-6">
            <v-btn rounded style="margin-top: 8px" color="white"
              >Kerja Sama</v-btn
            >
          </v-card>

          <v-card flat class="d-flex justify-center" color="" width="100%">
            <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

            <v-card
              flat
              width="100%"
              height="100%"
              class="d-flex align-center flex-column pa-16"
              transition="scale-transition"
            >
              <div class="home-content content-desc">
                <p class="">
                  perumusan, koordinasi, pemantauan dan evaluasi perumusan
                  kebijakan daerah, pelaksanaan tugas Perangkat Daerah, dan
                  pelaksanaan kebijakan daerah di bidang administrasi
                  pemerintahan dan fasilitasi penataan wilayah
                </p>
                <div class="d-flex align-center justify-center mt-10 mb-6">
                  <!-- <center> -->
                  <v-btn rounded large color="#00ADEB" dark> Fungsi </v-btn>
                  <!-- </center> -->
                </div>

                <p class="">
                  Penyelenggaraan koordinasi penyusunan dan menghimpun bahan
                  kebijakan umum bidang Pemerintahan, Penyelenggaraan fasilitasi
                  Pemerintahan, Penyelenggaraan evaluasi dan pelaporan Bagian
                  Pemerintahan, Penyelenggaraan fungsi lain sesuai dengan tugas
                  pokok dan fungsinya.
                </p>

                <div class="d-flex align-center justify-center mt-10 mb-6">
                  <!-- <center> -->
                  <v-btn rounded large color="#00ADEB" dark>
                    Rincian Tugas
                  </v-btn>
                  <!-- </center> -->
                </div>
                <ul>
                  <li>Membentuk Manusia Pancasila Yang Bertaqwa.</li>
                  <li>
                    Mempercepat Pertumbuhan Dan Pemerataan Pembangunan Berbasis
                    Lingkungan Dan Tata Ruang Yang Berkelanjutan. Mewujudkan
                    Tata Kelola Pemerintahan Yang Inovatif Dan
                  </li>
                  <li>
                    Kepemimpinan Yang Kolaboratif Antara Pemerintah Pusat,
                    Provinsi Dan Kabupaten/Kota.
                  </li>
                  <li>
                    Melahirkan Manusia Yang Berbudaya, Berkualitas, Bahagia Dan
                    Produktif. Meningkatkan Produktivitas Dan Daya Saing Usaha
                    Ekonomi
                  </li>
                  <li>Umat Yang Sejahtera Dan Adil.</li>
                </ul>

                <div class="d-flex align-center justify-center mt-10 mb-6">
                  <!-- <center> -->
                  <v-btn rounded large color="#00ADEB" dark>
                    Pelaksanaan Fasilitasi Kerja Sama
                  </v-btn>
                  <!-- </center> -->
                </div>
                <ul>
                  <li>
                    Menyelenggarakan penyusunan bahan rancangan dan rencana
                    kerja sama antar daerah, kerja sama dengan lembaga
                    kementerian/lembaga pemerintahan non kementerian, perguruan
                    tinggi dan pihak ketiga, meliputi perusahaan swasta yang
                    berbadan hukum dalam dan luar negeri, badan usaha milik
                    negara/daerah, badan usaha milik pemerintah negara/negara
                    bagian/daerah dan swasta di luar negeri, koperasi, perguruan
                    tinggi swasta, organisasi kemasyarakatan baik yang berbadan
                    hukum maupun yang tidak berbadan hukum dan perorangan;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan dan naskah perjanjian
                    kerja sama dalam negeri, luar negeri, dan pihak ketiga;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan kebijakan Gubernur dalam
                    penyelesaian perselisihan kerja sama luar negeri, kerjasama
                    antar daerah dan kerjasama dengan pihak ketiga;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan bahan penerbitan rekomendasi
                    perijinan perjalanan dinas ke luar negeri bagi kepala
                    daerah/wakil kepala daerah/aparatur sipil negara/anggota
                    Dewan Perwakilan Rakyat Daerah di lingkungan Pemerintah
                    Daerah Provinsi dan Pemerintah Daerah Kabupaten/Kota, serta
                    non aparatur sipil negara yang dibiayai oleh Anggaran
                    Pendapatan dan Belanja Daerah (APBD);
                  </li>
                  <li>
                    Melaksanakan penyusunan addendum, pembaharuan naskah kerja
                    sama (restarted cooperation agreement) dan/atau pengakhiran
                    kerja sama luar negeri, kerja sama antar daerah dan kerja
                    sama dengan pihak ketiga;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan rekomendasi keberlanjutan kerja
                    sama dengan pihak ketiga;
                  </li>

                  <li>
                    Menyelenggarakan koordinasi dan fasilitasi pelaksanaan kerja
                    sama antar daerah, meliputi kerja sama antara daerah
                    provinsi dengan daerah provinsi lain, kerja sama antara
                    Daerah Provinsi dengan Daerah Kabupaten/Kota, kerja sama
                    antara Daerah Provinsi dengan Daerah Kabupaten/Kota di luar
                    Daerah Provinsi, kerja samaantara Daerah Provinsi dengan
                    kementerian/lembaga pemerintah non-kementerian, serta
                    perguruan tinggi dalam negeri
                  </li>
                  <li>
                    Menyelenggarakan koordinasi dan fasilitasi pelaksanaan
                    evaluasi kerja sama luar negeri, kerja sama antar daerah dan
                    kerja sama dengan pihak ketiga serta penyelesaian
                    perselisihan;
                  </li>
                  <li>
                    Menyelenggarakan penyusunan rekomendasi keberlanjutan kerja
                    sama dengan pihak ketiga;
                  </li>
                  <li>
                    Menyelenggarakan koordinasi dalam upaya advokasispenanganan
                    permasalahan kerja sama luar negeri, kerjas sama dalam
                    negeri, dan kerja sama dengan pihak ketiga;
                  </li>
                  <li>
                    Menyelenggarakan koordinasi, fasilitasi dan pendampingan
                    kunjungan kerja Gubernur/Wakil Gubernur ke luar negeri;
                  </li>
                  <li>
                    Menyelenggarakan koordinasi dan fasilitasi penerimaan
                    kunjungan kerja mitra dan calon mitra kerja sama luar
                    negeri;
                  </li>
                </ul>
                <center>
                  <v-img
                    class="shrink"
                    :src="`${publicPath}kerjasama_ilustrasi.png`"
                    transition="scale-transition"
                    width="100%"
                    style="max-width: 300px"
                  />
                </center>

                <div class="kerja-sama-footer">
                  <div class="content-title">
                    <center>KERJA SAMA</center>
                  </div>
                  <div class="item">
                    <div class="item-img-holder">
                      <v-img
                        class="shrink"
                        :src="`${publicPath}otonom.png`"
                        transition="scale-transition"
                        width="100%"
                      />
                    </div>

                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="margin-top: 8px"
                        color="white"
                        >Kerja Sama Dalam Negri</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <div class="item-img-holder">
                      <v-img
                        class="shrink"
                        :src="`${publicPath}icon_aplikasi_izin.png`"
                        transition="scale-transition"
                        width="100%"
                      />
                    </div>

                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="margin-top: 8px"
                        color="white"
                        >Kerja Sama Luar Negri</v-btn
                      >
                    </v-card>
                  </div>
                  <div class="item">
                    <div class="item-img-holder">
                      <v-img
                        class="shrink"
                        :src="`${publicPath}icon_aplikasi_kerjasama.png`"
                        transition="scale-transition"
                        width="100%"
                      />
                    </div>
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn
                        class="kerja-sama-item-btn"
                        rounded
                        style="margin-top: 8px"
                        color="white"
                        >Kerja Sama Pihak Ketiga</v-btn
                      >
                    </v-card>
                  </div>
                </div>
              </div>
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "kerjasama",

  data: () => ({
    publicPath: process.env.BASE_URL,
  }),
};
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;
}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4caf50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
