<template>
  <Izin_luar_negeri />
</template>

<script>
  import Izin_luar_negeri from '@/components/layanan/izin_luar_negeri'

  export default {
    name: 'Izin_luar_negeri-view',

    components: {
      Izin_luar_negeri,
    },
  }
</script>
