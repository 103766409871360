<template>
  <Tentang_kami />
</template>

<script>
  import Tentang_kami from '@/components/kelembagaan/penlok'

  export default {
    name: 'Tentang_kami-view',

    components: {
      Tentang_kami,
    },
  }
</script>
