<template>
  <v-sheet class="px-12 pt-0 ">
    <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
      <v-btn to="/kelembagaan/kerjasama" absolute fab dark color="primary" style="left: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon>
      </v-btn>
      <v-btn to="/berita/berita" class="" absolute fab dark color="primary"
        style="right: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon>
      </v-btn>
      <v-row class="pa-12">
        
          <v-card  class=" rounded-xl  overflow-y-auto " elevation="12" style="height: calc(100vh - 280px)">
            <v-card flat class="d-flex justify-center mb-6">
              <v-btn rounded style="margin-top: 8px" color="white">Kerjasama Dalam Negri</v-btn>
            </v-card>
           
            <v-card flat class="d-flex justify-center" color="" width="100%">
              <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

              <v-card flat width="100%%" height="100%" class="d-flex align-center flex-column pa-16"
                transition="scale-transition">

                <div id="definisi" class="section-content d-flex align-center flex-column">
                  <v-img class="shrink content-img" :src="`${publicPath}otonomi_daerah_ilustrasi.png`" transition="scale-transition" width="50%" />
                  <div class="content-title">
                    DEFINISI
                  </div>
                  <div class="home-content">  
                    <p class="content-desc">
                      Kerja sama dalam negeri merupakan bentuk kolaborasi antara pemerintah daerah dengan daerah lain, kementerian,
                      lembaga pemerintahan non kementerian, dan universitas atau lembaga pendidikan negeri. Kerja sama dalam negeri
                      memiliki 3 (tiga) bentuk naskah kerja sama yaitu:<br>
                      1. Kesepakatan Bersama<br>
                      2. Perjanjian Kerja Sama<br>
                      3. Nota Kesepakatan<br>
                      Kesepakatan Bersama merupakan payung besar dari pelaksanaan kolaborasi atau kerja sama yang di tandatangani
                      oleh kepala daerah. Selanjutnya Kesepakatan Bersama tersebut ditindaklanjuti dengan kerja sama secara teknis
                      melalui Perjanjian Kerja Sama yang ditandatangani oleh kepala perangkat daerah. Sedangkan Nota Kesepakatan
                      merupakan dokumen kerja sama atau sinergitas antara pemerintah daerah dengan kementerian dan lembaga
                      pemerintahan non kementerian
                    </p>
                    
                   
                  </div>
                </div>
                
                <div id="dasar-hukum" class="section-content d-flex align-center flex-column">
                  <v-img class="shrink content-logo" :src="`${publicPath}penlok/law.png`" transition="scale-transition" width="100%" />
                  <div class="content-title">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn rounded style="margin-top: 8px" color="white">Dasar Hukum</v-btn>
                    </v-card>
                  </div>
                  <div class="home-content content-desc">  
                    <ul class="list-50">
                      <li>
                        Undang-Undang Nomor 32 Tahun 2014
                        tentang Pemerintahan Daerah
                      </li>
                      <li>
                        Peraturan Menteri Dalam Negeri Republik
                        Indonesia Nomor 22 Tahun 2020 tentang Tata
                        Cara Kerja Sama Daerah dengan Daerah Lain
                      </li>
                      <li>
                        Undang-Undang Nomor 6 Tahun 2023 tentang
                        Penetapan Peraturan Pemerintah Pengganti
                        Undang-Undang Nomor 2 Tahun 2022 tentang
                        Cipta Kerja menjandi Undang-Undang.
                      </li>
                      <li>
                        Peraturan Gubernur Jawa Barat Nomor 68
                        Tahun 2018 tentang Pedoman Koordinasi
                        Kerja Sama Daerah.
                      </li>
                      <li>
                        Peraturan Pemerintah (PP) Nomor 28 Tahun
                        2018 tentang Kerja Sama Daerah.
                      </li>
                    </ul>
                  </div>
                </div>

                <div id="dasar-hukum" class="section-content d-flex align-center flex-column">
                  
                  <div class="content-title">
                    <v-card flat class="d-flex justify-center mb-6">
                      <v-btn rounded style="margin-top: 8px" color="white">ALUR KERJA SAMA DAERAH DENGAN DAERAH LAIN (KSDD)</v-btn>
                    </v-card>
                  </div>
                  <v-img class="shrink content-logo-2" :src="`${publicPath}alurkerjasama.png`" transition="scale-transition" width="100%" />
                  
                </div>
                
                

              </v-card>

              
              
            </v-card>
          </v-card>
          
     
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'tentang_kami',

  data: () => ({
    publicPath: process.env.BASE_URL,

  }),
}
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00A85D;
  border-left: 3px solid #00A85D;
  border-right: 3px solid #00A85D;
}

.v-tab--active {
  background-color: #00A85D;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;

}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4CAF50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
