<template>
  <Gwpp />
</template>

<script>
import Gwpp from "@/components/layanan/gwpp";

export default {
  name: "Gwpp-view",

  components: {
    Gwpp,
  },
};
</script>
