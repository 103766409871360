<template>
    <v-sheet class="px-12 pt-0 ">
        <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
            <v-btn class="" to="/layanan/rupabumi" absolute fab dark color="primary" style="left: 24px; top: calc(50vh - 188px);">
                <v-icon dark x-large class="material-symbols-outlined mr-1">
                    arrow_left</v-icon>
            </v-btn>
            <v-btn to="/layanan/lppd" class="" absolute fab dark color="primary"
                style="right: 24px; top: calc(50vh - 188px);">
                <v-icon dark x-large class="material-symbols-outlined ml-1">
                    arrow_right</v-icon>
            </v-btn>
            <v-row class="">
                <v-card color="" width="100%" height="100%" flat class="d-flex justify-center align-center  mt-8">
                    <v-card flat width="350" height="100%" class="d-flex align-center ">

                        <v-card flat width="400" height="300" class="mt-2 d-flex align-center">

                            <v-card flat width="250" height="100%" class="d-flex justify-center align-center flex-column"
                                color="yellowx lighten-4">
                                <v-img class="shrink" width="180" contain :src="`${publicPath}icon_aplikasi_kerjasama.png`"
                                    transition="scale-transition" style="position: absolute; top: 0px" />
                                <v-card class="text-h5 primary--text" flat style="margin-top: 100px;">Fasilitas Kerjasama</v-card>
                            </v-card>


                        </v-card>
                        <!-- <h1>BERANDA</h1> -->
                    </v-card>
                    <v-card flat width="350" style="height: calc(100vh - 252px)"
                        class="d-flex justify-center align-center flex-column" color="secondaryx">
                        <v-card width="100%" height="350" elevation="18" class="rounded-xxl pa-10">
                            <v-card flat>
                                <h2 class="secondary--text">login</h2>
                            </v-card>
                            <v-card flat class="my-6 ">
                                <v-card flat class="d-flex align-center">
                                    <v-text-field label="Username" color="secondary" append-icon type="username"></v-text-field>
                                     <v-icon color="secondary">mdi-email-outline</v-icon>
                                </v-card>
                                <v-card flat class="d-flex align-center">
                                    <v-text-field label="Password" append-icon :type="(password)? 'password': 'text'"></v-text-field>
                                    <v-icon v-if="password"  @click="show_password()" color="secondary" class="material-symbols-outlined">visibility</v-icon>
                                     <v-icon v-else @click="hide_password()" color="secondary" class="material-symbols-outlined">visibility_off</v-icon>
                                     
                                     
                                </v-card>
                                <v-card flat class="d-flex justify-end mt-6">
                                    <p class="secondary--text">Forgot Password ?</p>
                                     
                                </v-card>
                            </v-card>
                        </v-card>
                        <!-- <v-card width="100%" class="d-flex justify-center" flat style="position: absolute; left: 0; right: 0; bottom: 24px; background-color: rgba(0,0,0,0);"> -->
                                    <v-btn rounded large color="#00ADEB" dark style="margin-top: -24px;">Sign-up</v-btn>
                                <!-- </v-card> -->


                    </v-card>
                </v-card>
            </v-row>
        </v-sheet>
    </v-sheet>
</template>

<script>
export default {
    name: 'layanan-kerjasama',

    data: () => ({
        publicPath: process.env.BASE_URL,
        password : true,

    }),
    methods:{
        show_password(){
            this.password = false;
        },
        hide_password(){
            this.password = true;
        }
    }
}
</script>
<style scoped>
.frame-page {
    border-bottom: 3px solid #00A85D;
    border-left: 3px solid #00A85D;
    border-right: 3px solid #00A85D;
}

.v-tab--active {
    background-color: #00A85D;
    color: white;
}
</style>
