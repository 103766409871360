<template>
  <v-sheet class="px-12 pt-0">
    <v-sheet
      class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page"
      style="height: calc(100vh - 188px)"
    >
      <v-btn
        class=""
        to="/layanan/rupabumi"
        absolute
        fab
        dark
        color="primary"
        style="left: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon
        >
      </v-btn>
      <v-btn
        to="/layanan/fasilitas_kerjasama"
        class=""
        absolute
        fab
        dark
        color="primary"
        style="right: 24px; top: calc(50vh - 188px)"
      >
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon
        >
      </v-btn>
      <v-row class="pa-12">
        <v-card
          class="rounded-xl overflow-y-auto"
          elevation="12"
          style="height: calc(100vh - 280px)"
        >
          <v-card flat class="d-flex justify-center mb-6">
            <v-btn rounded style="margin-top: 8px" color="white">GWPP</v-btn>
          </v-card>

          <v-card flat class="d-flex justify-center" color="" width="100%">
            <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

            <v-card
              flat
              width="100%%"
              height="100%"
              class="d-flex align-center flex-column pa-16"
              style="padding-top: 0px !important"
              transition="scale-transition"
            >
              <div
                id="definisi"
                class="section-content d-flex align-center flex-column"
              >
                <v-img
                  class="shrink content-logo-3"
                  :src="`${publicPath}icon_aplikasi_kerjasama.png`"
                  transition="scale-transition"
                  width="100%"
                />
                <div class="content-title">DEFINISI</div>
                <div class="home-content">
                  <p class="content-desc">
                    Gubernur sebagai wakil pemerintah pusat disingkat menjadi
                    GWPP, merupakan penyelenggara urusan pemerintahan yang
                    menjadi kewenangan pemerintah pusat di daerah berdasarkan
                    asas dekonsentrasi dan dalam rangka pelaksanaan tugas dan
                    wewenang GWPP sesuai dengan ketentuan peraturan
                    perundang-undangan.
                  </p>
                </div>
              </div>

              <div
                id="dasar-hukum"
                class="section-content d-flex align-center flex-column"
              >
                <v-img
                  class="shrink content-logo"
                  :src="`${publicPath}penlok/law.png`"
                  transition="scale-transition"
                  width="100%"
                />
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >Dasar Hukum</v-btn
                    >
                  </v-card>
                </div>
                <div class="home-content content-desc">
                  <ul class="list-50">
                    <li>
                      Undang-Undang No. 23 Tahun 2014 Tentang Pemerintahan
                      Daerah
                    </li>
                    <li>
                      Peraturan Pemerintah No. 19 Tahun 2022 tentang
                      Dekonsentrasi dan Tugas Pembantuan
                    </li>
                    <li>
                      Peraturan Pemerintah No. 33 Tahun 2018 tentang Pelaksanaan
                      Tugas dan Wewenang Gubernur sebagai Wakil Pemerintah Pusat
                    </li>
                    <li>
                      Peraturan Menteri Keuangan No. 89/PMK.02/2022 tentang
                      Standar Biaya Masukan Tahun Anggaran 2023
                    </li>
                    <li>
                      Peraturan Menteri Dalam Negeri No. 12 Tahun 2021 tentang
                      Peraturan Pelaksanaan PP 33/2018 tentang Pelaksanaan Tugas
                      dan Wewenang GWPP
                    </li>
                    <li>
                      Peraturan Menteri Keuangan No. 178/PMK.05/2018 tentang
                      Peraturan Pelaksanaan PP 33/2018 tentang
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="ruang-lingkup"
                class="section-content d-flex align-center flex-column"
                style="min-width: 100% !important"
              >
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >KEDUDUKAN</v-btn
                    >
                  </v-card>
                </div>
                <div
                  class="home-content content-desc"
                  style="min-width: 100% !important"
                >
                  <ul style="min-width: 100% !important">
                    <li>
                      GWPP perpanjangan tangan presiden, bertindak selaku wakil
                      presiden di provinsi dalam melakukan pembinaan dan
                      pengawasan urusan pemerintahan daerah Kabupaten/Kota;
                    </li>
                    <li>
                      GWPP bertanggungjawab kepada presiden melalui menteri
                      dalam negeri;
                    </li>
                    <li>
                      GWPP memastikan perencanaan pembangunan, produk hukum, dan
                      penganggaran di Kabupaten/Kota tidak bertentangan dengan
                      peraturan yang lebih tinggi;
                    </li>
                    <li>
                      Memperpendek rentan kendali pemerintahan dari pusat ke
                      daerah Kabupaten/Kota;
                    </li>
                    <li>
                      GWPP sebagai fasilitator bagi Kabupaten/Kota untuk dapat
                      berkoordinasi / konsultasi terkait kebijakan / program
                      dengan pemerin- tah pusat (Kementerian/Lembaga); dan
                    </li>
                    <li>
                      GWPP memastikan seluruh APBN yg ada dialokasikan di
                      Kab/Kota dan Instansi Vertikal secara tepat, efektif,
                      efisien dan akuntabel
                    </li>
                  </ul>
                </div>
              </div>

              <div
                id="ruang-lingkup"
                class="section-content d-flex align-center flex-column"
                style="min-width: 100% !important"
              >
                <div class="content-title">
                  <v-card flat class="d-flex justify-center mb-6">
                    <v-btn rounded style="margin-top: 8px" color="white"
                      >FUNGSI SEKRETARIAT GWPP (BIRO PEMERINTAHAN DAN OTONOMI
                      DAERAH)</v-btn
                    >
                  </v-card>
                </div>
                <div
                  class="home-content content-desc"
                  style="min-width: 100% !important"
                >
                  <ul style="min-width: 100% !important">
                    <li>
                      Pengoordinasian, penyusunan, perencanaan program dan
                      anggaran perangkat Gubernur;
                    </li>
                    <li>
                      Pengelolaan urusan tata usaha, perlengkapan dan rumah
                      tangga, Pelayanan administrasi keuangan;
                    </li>
                    <li>
                      Inventarisasi pelaporan pertanggung jawaban pelaksanaan
                      tugas dan fungsi perangkat Gubernur;
                    </li>
                    <li>
                      Penyusunan laporan kinerja dan keuangan, pengelolaan data
                      dan informasi
                    </li>
                    <li>
                      Pemantauan dan evaluasi pelaksanaan tugas dan wewenang
                      Gubernur; dan
                    </li>
                    <li>Pelaksanaan fungsi lain dari Gubernur</li>
                  </ul>
                </div>
              </div>
              <div
                id="dasar-hukum"
                class="section-content d-flex align-center flex-column"
              >
                <v-img
                  class="shrink content-logo-2"
                  :src="`${publicPath}sotk.png`"
                  transition="scale-transition"
                  width="100%"
                />
              </div>
            </v-card>
          </v-card>
        </v-card>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: "layanan-rupabumi",

  data: () => ({
    publicPath: process.env.BASE_URL,
    password: true,
  }),
  methods: {
    show_password() {
      this.password = false;
    },
    hide_password() {
      this.password = true;
    },
  },
};
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}
.frame-page {
  border-bottom: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab--active {
  background-color: #00a85d;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;
}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4caf50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
