<template>
  <Sister_province />
</template>

<script>
  import Sister_province from '@/components/beranda/sister_province'

  export default {
    name: 'Sister_province-view',

    components: {
      Sister_province,
    },
  }
</script>
