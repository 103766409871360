import Vue from "vue";
import VueRouter from "vue-router";
import beranda from "../views/beranda.vue";
import layanan from "../views/layanan.vue";
import sister_province from "../views/sister_province.vue";
import informasi_terkini from "../views/informasi_terkini.vue";
import international_partners from "../views/international_partners.vue";
import rupabumi from "../views/rupabumi.vue";
import gwpp from "../views/gwpp.vue";
import fasilitas_kerjasama from "../views/fasilitas_kerjasama.vue";
import lppd from "../views/lppd.vue";
import paw_dprd from "../views/paw_dprd.vue";
import izin_luar_negeri from "../views/izin_luar_negeri.vue";
import penetapan_lokasi from "../views/penetapan_lokasi.vue";
import tentang_kami from "../views/tentang_kami.vue";
import struktur_organisasi from "../views/struktur_organisasi.vue";
import pemerintahan from "../views/pemerintahan.vue";
import otonomi_daerah from "../views/otonomi_daerah.vue";
import kerjasama from "../views/kerjasama.vue";
import berita from "../views/berita.vue";

import penlok from "../views/penlok.vue";
import kerjasama_dalam_negri from "../views/kerjasama_dalam_negri.vue";
import kontak from "../views/kontak.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "beranda",
    component: beranda,
    // children: [
    //   {
    //     path: 'layanan',
    //     name: 'layanan',
    //     component: layanan,
    //   }
    // ]
  },
  {
    path: "/beranda/layanan",
    name: "beranda-layanan",
    component: layanan,
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    // component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/beranda/sister_province",
    name: "beranda-sister_province",
    component: sister_province,
  },
  {
    path: "/beranda/informasi_terkini",
    name: "beranda-informasi_terkini",
    component: informasi_terkini,
  },
  {
    path: "/beranda/international_partners",
    name: "beranda-international_partners",
    component: international_partners,
  },
  // layanan
  {
    path: "/layanan/rupabumi",
    name: "layanan-rupabumi",
    component: rupabumi,
  },
  {
    path: "/layanan/gwpp",
    name: "layanan-gwpp",
    component: gwpp,
  },
  {
    path: "/layanan/fasilitas_kerjasama",
    name: "layanan-fasilitas_kerjasama",
    component: fasilitas_kerjasama,
  },
  {
    path: "/layanan/lppd",
    name: "layanan-lppd",
    component: lppd,
  },
  {
    path: "/layanan/paw_dprd",
    name: "layanan-pa_wdprd",
    component: paw_dprd,
  },
  {
    path: "/layanan/izin_luar_negeri",
    name: "layanan-izin_luar_negeri",
    component: izin_luar_negeri,
  },
  {
    path: "/layanan/penetapan_lokasi",
    name: "layanan-penetapan_lokasi",
    component: penetapan_lokasi,
  },
  // kelembagaan
  {
    path: "/kelembagaan/tentang_kami",
    name: "kelembagaan-tentang_kami",
    component: tentang_kami,
  },
  {
    path: "/kelembagaan/struktur_organisasi",
    name: "kelembagaan-struktur_organisasi",
    component: struktur_organisasi,
  },
  {
    path: "/kelembagaan/pemerintahan",
    name: "kelembagaan-pemerintahan",
    component: pemerintahan,
  },
  {
    path: "/kelembagaan/otonomi_daerah",
    name: "kelembagaan-otonomi_daerah",
    component: otonomi_daerah,
  },
  {
    path: "/kelembagaan/kerjasama",
    name: "kelembagaan-kerjasama",
    component: kerjasama,
  },
  {
    path: "/kelembagaan/kerjasama-dalam-negri",
    name: "kelembagaan-kerjasama-dalam-negri",
    component: kerjasama_dalam_negri,
  },
  {
    path: "/kelembagaan/penlok",
    name: "kelembagaan-penlok",
    component: penlok,
  },
  // berita
  {
    path: "/berita/berita",
    name: "berita-berita",
    component: berita,
  },

  //new router

  {
    path: "/kontak",
    name: "kontak",
    component: kontak,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
