<template>
  <v-sheet class="px-12 pt-0 ">
    <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
      <v-btn class="" to="/beranda/layanan"  absolute fab dark color="primary" style="left: 24px; top: calc(50vh - 188px);">
          <v-icon dark x-large class="material-symbols-outlined mr-1">
            arrow_left</v-icon>
        </v-btn>
        <v-btn to="/beranda/informasi_terkini" class="" absolute fab dark color="primary" style="right: 24px; top: calc(50vh - 188px);">
          <v-icon dark x-large class="material-symbols-outlined ml-1">
            arrow_right</v-icon>
        </v-btn>
      <v-row class="">
        <v-card color="" width="100%" height="100%" flat class="d-flex justify-center align-center  mt-8">
          <v-card flat width="350" height="100%" class="d-flex align-center flex-column" >
            <v-card flat width="100%">
              <h1>Sister Province</h1>
            </v-card>
            <v-card flat width="100%" class="mt-2">
              <p style="text-align: justify;">Biro Pemerintahan dan Otonomi Daerah <br> Provinsi Jawa Barat hadir melayani</p>
            </v-card>
            <v-card flat width="400" class="mt-2 d-flex align-center">
              
                 <v-btn rounded large color="#00ADEB" dark class="mr-6">
                  Kontak
                </v-btn>
              
                 <v-btn text color="#00ADEB" >
                  Aplikasi
                </v-btn>
              
             
            </v-card>
            <!-- <h1>BERANDA</h1> -->
          </v-card>
          <v-card flat width="50%" class="d-flex justify-center align-center flex-wrap" color="greenx lighten-4">
            <v-card flat width="200" height="185" class="d-flex justify-center align-center flex-column" >
                 <v-img width="60%" class="shrink" contain :src="`${publicPath}logo_sister_province_1.png`" transition="scale-transition"/>
            </v-card>
            <v-card flat width="200" height="185" class="d-flex justify-center align-center flex-column" >
                 <v-img width="60%" class="shrink" contain :src="`${publicPath}logo_sister_province_2.png`" transition="scale-transition"/>
            </v-card>
            <v-card flat width="200" height="185" class="d-flex justify-center align-center flex-column" >
                 <v-img width="60%" class="shrink" contain :src="`${publicPath}logo_sister_province_3.png`" transition="scale-transition"/>
            </v-card>
            <v-card flat width="200" height="185" class="d-flex justify-center align-center flex-column" >
                 <v-img width="60%" class="shrink" contain :src="`${publicPath}logo_sister_province_2.png`" transition="scale-transition"/>
            </v-card>
            <v-card flat width="200" height="185" class="d-flex justify-center align-center flex-column" >
                 <v-img width="60%" class="shrink" contain :src="`${publicPath}logo_sister_province_3.png`" transition="scale-transition"/>
            </v-card>
            <v-card flat width="200" height="185" class="d-flex justify-center align-center flex-column" >
                 <v-img width="60%" class="shrink" contain :src="`${publicPath}logo_sister_province_1.png`" transition="scale-transition"/>
            </v-card>
            
            
           
          </v-card>
        </v-card>
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'beranda-sister_province',

  data: () => ({
    publicPath: process.env.BASE_URL,

  }),
}
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00A85D;
  border-left: 3px solid #00A85D;
  border-right: 3px solid #00A85D;
}

.v-tab--active {
  background-color: #00A85D;
  color: white;
}
</style>
