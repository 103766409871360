<template>
  <international_partners />
</template>

<script>
import international_partners from '@/components/beranda/international_partners'

  export default {
    name: 'international_partners-view',

    components: {
      international_partners,
    },
  }
</script>
