<template>
  <Struktur_organisasi />
</template>

<script>
  import Struktur_organisasi from '@/components/kelembagaan/struktur_organisasi'

  export default {
    name: 'Struktur_organisasi-view',

    components: {
      Struktur_organisasi,
    },
  }
</script>
