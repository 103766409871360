<template>
  <Penetapan_lokasi />
</template>

<script>
  import Penetapan_lokasi from '@/components/layanan/penetapan_lokasi'

  export default {
    name: 'Penetapan_lokasi-view',

    components: {
      Penetapan_lokasi,
    },
  }
</script>
