<template>
    <contact />
  </template>
  
  <script>
    import contact from '@/components/beranda/kontak'
  
    export default {
      name: 'contact-view',
  
      components: {
        contact,
      },
    }
  </script>
  