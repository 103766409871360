<template>
    <v-sheet class="px-12 pt-0 ">
        <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
            <v-btn class="" to="/beranda/sister_province" absolute fab dark color="primary"
                style="left: 24px; top: calc(50vh - 188px);">
                <v-icon dark x-large class="material-symbols-outlined mr-1">
                    arrow_left</v-icon>
            </v-btn>
            <v-btn to="/beranda/international_partners" class="" absolute fab dark color="primary"
                style="right: 24px; top: calc(50vh - 188px);">
                <v-icon dark x-large class="material-symbols-outlined ml-1">
                    arrow_right</v-icon>
            </v-btn>
            <v-row class="">
                <v-card color="" width="100%"  flat class="d-flex justify-center align-center  mt-8">
                    <v-card flat width="350" height="" class="d-flex align-center flex-column">
                        <v-card flat width="100%">
                            <h1>Informasi Terkini</h1>
                        </v-card>
                        <v-card flat width="100%" class="mt-2">
                            <p style="text-align: justify;">Biro Pemerintahan dan Otonomi Daerah <br> Provinsi Jawa Barat
                                hadir melayani</p>
                        </v-card>
                        <v-card flat width="400" class="mt-2 d-flex align-center">

                            <v-btn rounded large color="#00ADEB" dark class="mr-6">
                                Kontak
                            </v-btn>

                            <v-btn text color="#00ADEB">
                                Aplikasi
                            </v-btn>


                        </v-card>
                        <!-- <h1>BERANDA</h1> -->
                    </v-card>
                    <v-card flat width="50%" height="100%" class="d-flex justify-center align-center flex-column"
                        color="greenx lighten-4">
                        <v-card flat width="100%" height="420" class="d-flex justify-center align-center flex-column" color="">
                            <v-img width="600" class="shrink" contain :src="`${publicPath}frame_informasi_terkini.png`"
                                transition="scale-transition" style="position: absolute;"/>
                            <v-img width="400" class="shrink " contain :src="`${publicPath}berita/informasi_terkini.png`"
                                transition="scale-transition" style="position: absolute; "/>
                        </v-card>
                    </v-card>
                </v-card>
            </v-row>
        </v-sheet>
    </v-sheet>
</template>

<script>
export default {
    name: 'beranda-informasi_terkini.',

    data: () => ({
        publicPath: process.env.BASE_URL,

    }),
}
</script>
<style scoped>
.frame-page {
    border-bottom: 3px solid #00A85D;
    border-left: 3px solid #00A85D;
    border-right: 3px solid #00A85D;
}

.v-tab--active {
    background-color: #00A85D;
    color: white;
}</style>
