<template>
  <v-sheet class="px-12 pt-8 pb-0">
    <v-sheet
      class="px-6 pt-6 pb-0 rounded-xl rounded-b-0 frame-page"
      height="104px"
    >
      <v-toolbar elevation="4" class="rounded-pill">
        <v-tabs fixed-tabs class="rounded-pill">
          <v-tabs-slider color="secondary"></v-tabs-slider>
          <v-tab to="/" style="border-radius: 32px 0px 0px 32px">
            Beranda
          </v-tab>
          <v-tab to="/layanan/rupabumi">
            <!-- <v-icon color="primary">mdi-chevron-down</v-icon> -->

            <v-menu offset-y close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  Layanan
                  <v-icon color="primary" class="material-symbols-outlined"
                    >arrow_drop_down</v-icon
                  >
                </div>
              </template>
              <v-list color="primary" dark dense width="100%">
                <v-list-item
                  v-for="(item, index) in menu_layanan"
                  :key="index"
                  style="cursor: pointer"
                >
                  <v-btn :to="item.path" text width="100%">{{
                    item.title
                  }}</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
          <v-tab to="/kelembagaan/tentang_kami">
            <v-menu offset-y close-on-content-click>
              <template v-slot:activator="{ on, attrs }">
                <div v-bind="attrs" v-on="on">
                  Kelembagaan
                  <v-icon color="primary" class="material-symbols-outlined"
                    >arrow_drop_down</v-icon
                  >
                </div>
              </template>
              <v-list color="primary" dark dense width="100%">
                <v-list-item
                  v-for="(item, index) in menu_kelembagaan"
                  :key="index"
                  style="cursor: pointer"
                >
                  <v-btn :to="item.path" text width="100%">{{
                    item.title
                  }}</v-btn>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-tab>
          <v-tab disabled style="opacity: 1">
            <v-img
              class="shrink"
              contain
              :src="`${publicPath}logo-pemotda.png`"
              transition="scale-transition"
              width="126"
            />
          </v-tab>
          <v-tab to="/berita/berita"> Berita </v-tab>
          <v-tab> Produk Hukum </v-tab>
          <v-tab disabled style="opacity: 1">
            <v-switch
              v-model="switch1"
              background-color=""
              inset
              label="Eng"
              class="mt-6"
            ></v-switch>
          </v-tab>
        </v-tabs>
      </v-toolbar>
    </v-sheet>

    <!-- <v-app-bar app color="white" class="rounded-pill">
            <v-tabs align-with-title  fixed-tabs>
                <v-tabs-slider color="secondary"></v-tabs-slider>
                <v-tab>
                    Beranda
                </v-tab>
                <v-tab>
                    Layanan
                </v-tab>
                <v-tab>
                    Kelembagaan
                </v-tab>
            </v-tabs>
            <v-spacer></v-spacer>
            <div style="width: 33%" class="d-flex justify-center">
                <v-img class="shrink ml-8" contain :src="`${publicPath}logo-pemotda.png`" transition="scale-transition"
                    width="110" />
            </div>

            <v-spacer></v-spacer>
            <v-tabs align-with-title  fixed-tabs>
                <v-tab>
                    Berita
                </v-tab>
                <v-tab>
                    Produk Hukum
                </v-tab>
                <v-tab>
                    Kelembagaan
                </v-tab>
            </v-tabs>


        </v-app-bar> -->
  </v-sheet>
</template>

<script>
export default {
  name: "app_bar",

  data: () => ({
    publicPath: process.env.BASE_URL,
    switch1: false,
    menu_layanan: [
      { title: "Rupabumi", path: "/layanan/rupabumi" },
      { title: "GWPP", path: "/layanan/gwpp" },
      { title: "Fasilitas Kerjasama", path: "/layanan/fasilitas_kerjasama" },
      { title: "LPPD", path: "/layanan/lppd" },
      { title: "PAW DPRD", path: "/layanan/paw_dprd" },
      { title: "Izin Ke Luar Negeri", path: "/layanan/izin_luar_negeri" },
      { title: "Penetapan Lokasi", path: "/layanan/penetapan_lokasi" },
    ],
    menu_kelembagaan: [
      { title: "tentang kami", path: "/kelembagaan/tentang_kami" },
      {
        title: "struktur organisasi",
        path: "/kelembagaan/struktur_organisasi",
      },
      { title: "fasilitas pemerintahan", path: "/kelembagaan/pemerintahan" },
      {
        title: "fasilitas otonomi daerah",
        path: "/kelembagaan/otonomi_daerah",
      },
      { title: "penetapan lokasi", path: "/kelembagaan/penlok" },
      { title: "fasilitas kerjasama", path: "/kelembagaan/kerjasama" },
      {
        title: "kerjasama-dalam-negri",
        path: "/kelembagaan/kerjasama-dalam-negri",
      },
    ],
  }),
};
</script>
<style scoped>
/* .v-tab.v-tab--active{
        background-color: #00A85D;
        color : white;
    }
    .v-tab {
        color: #00A85D;
        
    }
     */
.frame-page {
  border-top: 3px solid #00a85d;
  border-left: 3px solid #00a85d;
  border-right: 3px solid #00a85d;
}

.v-tab.v-tab--active {
  background-color: #00a85d;
  color: white;
}

.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  color: #00a85d;
}
</style>
