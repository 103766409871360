<template>
  <Otonomi_daerah />
</template>

<script>
  import Otonomi_daerah from '@/components/kelembagaan/otonomi_daerah'

  export default {
    name: 'Otonomi_daerah-view',

    components: {
      Otonomi_daerah,
    },
  }
</script>
