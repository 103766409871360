<template>
    <v-sheet class="px-12 pt-0 ">
        <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
            <v-btn to="/kelembagaan/tentang_kami" absolute fab dark color="primary"
                style="left: 24px; top: calc(50vh - 188px);">
                <v-icon dark x-large class="material-symbols-outlined mr-1">
                    arrow_left</v-icon>
            </v-btn>
            <v-btn disabled to="/produk_hukum/produk_hukum" class="" absolute fab dark color="primary"
                style="right: 24px; top: calc(50vh - 188px);">
                <v-icon dark x-large class="material-symbols-outlined ml-1">
                    arrow_right</v-icon>
            </v-btn>
            <v-row class="pa-12">

                <v-card flat width="100%" class="   overflow-y-auto "
                    style="height: calc(100vh - 280px)">
                    <v-card flat class="d-flex  justify-center py-8" color="" width="100%">
                        <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->
                        <v-card flat class="rounded-r-0 rounded-xl"  width="40%">
                            <v-img width="500" class="shrink " contain :src="`${publicPath}berita/informasi_terkini.png`"
                                    transition="scale-transition" style="position: absolute; "/>
                        </v-card>
                        <v-card class="rounded-l-0 d-flex justify-center" flat width="40%">
                            <v-card flat width="500">
                                <h1 class="mb-6">Ridwan Kamil Memberikan Penghargaan</h1>
                                <p>Biro Pemerintahan dan Otonomi Daerah Provinsi Jawa Barat
                                    mendapatkan penghargaan sebagai biro terbarik 2023</p>
                                <p>Dimana Biro Pemerintahan dan Otonomi Daerah mempunyai
                                    tugas sebagai memimpin, memotivasi, menyelenggarakan
                                    perumusan bahan kebijakan umum (selengkapnya,
                                    pemotda.jabarprov.go.id)</p>
                            </v-card>
                        </v-card>

                        <!-- Your content for the second column goes here -->
                        

                    </v-card>
                    <v-card flat class="d-flex  justify-center py-8" color="" width="100%">
                        <v-card class="rounded-l-0 d-flex justify-center" flat width="40%">
                                <v-card flat width="500">
                                    <h1 class="mb-6">Ridwan Kamil Memberikan Penghargaan</h1>
                                    <p>Biro Pemerintahan dan Otonomi Daerah Provinsi Jawa Barat
                                        mendapatkan penghargaan sebagai biro terbarik 2023</p>
                                    <p>Dimana Biro Pemerintahan dan Otonomi Daerah mempunyai
                                        tugas sebagai memimpin, memotivasi, menyelenggarakan
                                        perumusan bahan kebijakan umum (selengkapnya,
                                        pemotda.jabarprov.go.id)</p>
                                </v-card>
                            </v-card>
                        <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->
                        <v-card flat class="rounded-l-0 rounded-xl"  width="40%">
                            <v-img width="500" class="shrink " contain :src="`${publicPath}berita/informasi_terkini.png`"
                                    transition="scale-transition" style="position: absolute; "/>
                        </v-card>
                        
                        

                        <!-- Your content for the second column goes here -->
                        

                    </v-card>
                    <v-card flat class="d-flex  justify-center py-8" color="" width="100%">
                            <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->
                            <v-card flat class="rounded-r-0 rounded-xl"  width="40%">
                                <v-img width="500" class="shrink " contain :src="`${publicPath}berita/informasi_terkini.png`"
                                        transition="scale-transition" style="position: absolute; "/>
                            </v-card>
                            <v-card class="rounded-l-0 d-flex justify-center" flat width="40%">
                                <v-card flat width="500">
                                    <h1 class="mb-6">Ridwan Kamil Memberikan Penghargaan</h1>
                                    <p>Biro Pemerintahan dan Otonomi Daerah Provinsi Jawa Barat
                                        mendapatkan penghargaan sebagai biro terbarik 2023</p>
                                    <p>Dimana Biro Pemerintahan dan Otonomi Daerah mempunyai
                                        tugas sebagai memimpin, memotivasi, menyelenggarakan
                                        perumusan bahan kebijakan umum (selengkapnya,
                                        pemotda.jabarprov.go.id)</p>
                                </v-card>
                            </v-card>

                            <!-- Your content for the second column goes here -->
                        

                        </v-card>
                </v-card>


            </v-row>
        </v-sheet>
    </v-sheet>
</template>

<script>
export default {
    name: 'struktur_organisasi',

    data: () => ({
        publicPath: process.env.BASE_URL,

    }),
}
</script>
<style scoped>
.frame-page {
    border-bottom: 3px solid #00A85D;
    border-left: 3px solid #00A85D;
    border-right: 3px solid #00A85D;
}

.v-tab--active {
    background-color: #00A85D;
    color: white;
}
</style>
