<template>
  <v-sheet class="px-12 pt-0 ">
    <v-sheet class="px-8 pt-0 pb-6 rounded-t-0 rounded-xl frame-page" style="height: calc(100vh - 188px)">
      <v-btn to="/kelembagaan/pemerintahan" absolute fab dark color="primary" style="left: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined mr-1">
          arrow_left</v-icon>
      </v-btn>
      <v-btn to="/kelembagaan/penlok" class="" absolute fab dark color="primary"
        style="right: 24px; top: calc(50vh - 188px);">
        <v-icon dark x-large class="material-symbols-outlined ml-1">
          arrow_right</v-icon>
      </v-btn>
      <v-row class="pa-12">
        
          <v-card  class=" rounded-xl  overflow-y-auto " elevation="12" style="height: calc(100vh - 280px)">
            <v-card flat class="d-flex justify-center mb-6">
              <v-btn rounded style="margin-top: 8px" color="white">Otonomi Daerah</v-btn>
            </v-card>
           
            <v-card flat class="d-flex justify-center" color="" width="100%">
              <!-- <v-btn style="position: absolute;left: 0; right: 0; top: 0;">Tentang Kami</v-btn> -->

              <v-card flat width="50%" height="100%" class="d-flex align-center flex-column pa-16"
                transition="scale-transition">
                <div class="home-content">
                  <p class="">
                    perumusan, koordinasi, pemantauan dan evaluasi perumusan
                    kebijakan daerah, pelaksanaan tugas Perangkat Daerah, dan
                    pelaksanaan kebijakan daerah di bidang administrasi
                    pemerintahan dan fasilitasi penataan wilayah
                  </p>
                  <div class="d-flex align-center justify-center mt-10 mb-6">
                    <!-- <center> -->
                    <v-btn rounded large color="#00ADEB" dark>
                      Fungsi
                    </v-btn>
                    <!-- </center> -->
                  </div>

                  <p class="">
                     Penyelenggaraan koordinasi penyusunan dan menghimpun
                    bahan kebijakan umum bidang Pemerintahan, Penyelenggaraan
                    fasilitasi Pemerintahan, Penyelenggaraan evaluasi dan
                    pelaporan Bagian Pemerintahan, Penyelenggaraan fungsi lain
                    sesuai dengan tugas pokok dan fungsinya.
                  </p>

                  <div class="d-flex align-center justify-center mt-10 mb-6">
                    <!-- <center> -->
                    <v-btn rounded large color="#00ADEB" dark>
                      Rincian Tugas
                    </v-btn>
                    <!-- </center> -->
                  </div>
                  <ul>

                    <li>Membentuk Manusia Pancasila Yang Bertaqwa.</li>
                    <li>Mempercepat Pertumbuhan Dan Pemerataan Pembangunan
                    Berbasis Lingkungan Dan Tata Ruang Yang Berkelanjutan.
                    Mewujudkan Tata Kelola Pemerintahan Yang Inovatif Dan</li>
                    <li>Kepemimpinan Yang Kolaboratif Antara Pemerintah Pusat,
                    Provinsi Dan Kabupaten/Kota.</li>
                    <li>Melahirkan Manusia Yang Berbudaya, Berkualitas, Bahagia
                    Dan Produktif.
                    Meningkatkan Produktivitas Dan Daya Saing Usaha Ekonomi</li>
                    <li>Umat Yang Sejahtera Dan Adil.</li>
                  </ul>
                </div>



              </v-card>
              <v-card flat width="40%" class="d-flex justify-center align-center" color="greenx lighten-4">
                <v-card flat width="90%" height="100%">
                  <v-img class="shrink " :src="`${publicPath}otonomi_daerah_ilustrasi.png`" transition="scale-transition"
                    width="100%" />
                </v-card>
              </v-card>
            </v-card>
          </v-card>
          
     
      </v-row>
    </v-sheet>
  </v-sheet>
</template>

<script>
export default {
  name: 'otonomi_daerah',

  data: () => ({
    publicPath: process.env.BASE_URL,

  }),
}
</script>
<style scoped>
.frame-page {
  border-bottom: 3px solid #00A85D;
  border-left: 3px solid #00A85D;
  border-right: 3px solid #00A85D;
}

.v-tab--active {
  background-color: #00A85D;
  color: white;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  position: relative;
  padding: 8px;
  margin: 4px;

}

li::before {
  content: "";

  position: absolute;
  left: -24px;
  /* Sesuaikan dengan kebutuhan Anda */
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
  height: 16px;
  background-color: #ffffff;
  /* Warna hijau pada pinggiran */
  border-radius: 50%;
  display: inline-block;
  border: 3px solid #4CAF50;
  /* Warna putih di tengah */
  box-sizing: border-box;
  /* Untuk memasukkan ketebalan border ke dalam ukuran elemen */
  margin-right: 8px;
  /* Jarak antara tanda bulu dan teks */
}
</style>
